<template>
  <b-navbar
    id="template-header"
    class="default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
    toggleable="lg"
  >
    <via-orders-api ref="ViaOrdersApi"></via-orders-api>
    <via-lots-api ref="ViaLotsApi"></via-lots-api>
    <div
      class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
      v-if="ocd == 'true'"
    >
      <router-link
        class="navbar-brand brand-logo mr-4"
        to="/site/dashboard_store_tv"
      >
        <img
          src="@/assets/images/icons/ocd/ocd_branco.png"
          class="h-100 mt-4"
          alt="logo"
          style="width: 30%; max-width: 30%"
        />
      </router-link>
      <router-link
        class="navbar-brand brand-logo-mini"
        to="/site/dashboard_store_tv"
      >
        <img
          src="@/assets/images/icons/ocd/ocd_branco.png"
          class="h-100"
          alt="logo"
        />
      </router-link>
    </div>
    <div
      class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
      v-else
    >
      <router-link
        class="navbar-brand brand-logo mr-4"
        to="/site/orders_totals_per_operation"
      >
        <img src="@/assets/images/logo.png" class="h-100" alt="logo" />
      </router-link>
      <router-link
        class="navbar-brand brand-logo-mini"
        to="/site/orders_totals_per_operation"
      >
        <img src="@/assets/images/logo-mini.png" class="h-100" alt="logo" />
      </router-link>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <button
        class="navbar-toggler navbar-toggler align-self-center d-lg-block"
        type="button"
        @click="toggleSidebar()"
      >
        <span class="ti-layout-grid2"></span>
      </button>

      <span v-if="!isMobile && isOnDashboards" id="counters">
        <span class="ml-3"
          >Concluído (dia)<span
            ><b-badge pill variant="outline-success" class="ml-2"
              >{{ today_done_quantity }}
              <b-badge pill variant="success" class="ml-1">{{
                today_done_quantity_scheduled
              }}</b-badge></b-badge
            ></span
          ></span
        >
        <span class="ml-3"
          >Ocorrência (dia)<span
            ><b-badge pill variant="outline-danger" class="ml-2"
              >{{ today_final_occurrence_quantity }}
              <b-badge pill variant="danger" class="ml-1">{{
                today_final_occurrence_quantity_scheduled
              }}</b-badge></b-badge
            ></span
          ></span
        >
        <span class="ml-3"
          >Produtividade (%)<span
            ><b-badge pill variant="outline-info" class="ml-2"
              >{{ productivity_quantity }}%
              <b-badge pill variant="info" class="ml-1"
                >{{ productivity_quantity_scheduled }}%</b-badge
              ></b-badge
            ></span
          ></span
        >
      </span>

      <b-form-group class="mt-3 ml-3">
        <b-input-group>
          <b-input-group slot="append">
            <b-button
              class="btn btn-sm btn-outline-default fa fa-search"
              @click="searchOrder"
            ></b-button>
          </b-input-group>
          <b-form-input
            class="form-control"
            v-model="orderFilterSearch"
            @keyup.enter="searchOrder"
            placeholder="Buscar pedido"
          />
        </b-input-group>
      </b-form-group>

      <b-navbar-nav class="navbar-nav-right ml-auto">
        <ViaNotifications
          :key="$route.name"
          :is-on-dashboards="isOnDashboards"
        />
        <b-nav-item-dropdown right class="nav-profile">
          <template slot="button-content">
            <span
              class="nav-link dropdown-toggle"
              id="profileDropdown"
              href="javascript:void(0);"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div class="nav-profile-img">
                <i class="btn icon-user p-3"></i>
              </div>
            </span>
          </template>
          <b-dropdown-item class="preview-item" @click="editCurrentUser()">
            <i class="ti-settings text-primary"></i> Perfil
          </b-dropdown-item>
          <b-dropdown-item class="preview-item" @click="clearSession()">
            <i class="ti-power-off text-primary"></i> Sair
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <button
        class="navbar-toggler navbar-toggler-right align-self-center"
        type="button"
        @click="toggleMobileSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </b-navbar>
</template>

<script>
import { useApi } from "../api/guard";
import ability from "../config/ability";
import User from "../services/user";
import guard from "../api/guard";
import ViaOrdersApi from "../api/ViaOrdersApi";
import ViaLotsApi from "../api/ViaLotsApi.vue";
import ViaNotifications from "../components/header/ViaNotifications.vue";

export default {
  name: "app-header",

  mixins: [guard],

  components: {
    ViaOrdersApi,
    ViaLotsApi,
    ViaNotifications,
  },

  data() {
    return {
      windowWidth: "",
      timer: "",
      today_done_quantity: 0,
      today_final_occurrence_quantity: 0,
      productivity_quantity: 0,
      today_done_quantity_scheduled: 0,
      today_final_occurrence_quantity_scheduled: 0,
      productivity_quantity_scheduled: 0,
      userService: null,
      api: useApi(),
      ocd: process.env.VUE_APP_OCD_MODE,
      orderFilterSearch: null,
    };
  },

  async mounted() {
    if (this.isOnDashboards) {
      this.companyKind = this.$can("see", "internal-companies")
        ? "interno"
        : "externo";
      this.refreshOrdersPerCustomerTotals();
    }
    this.userService = new User();
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    if (this.ocd) {
      document.title = "OCD - One Click Delivery";
    }
  },

  computed: {
    isMobile() {
      return this.windowWidth <= 820;
    },
    isOnDashboards() {
      return [
        "dashboard_support_point",
        "dashboard_store",
        "dashboard_store_tv",
        "orders_per_customer",
        "orders_totals_per_operation",
        "visitors_states_summary",
        "dashboard_transporter",
      ].includes(this.$route.name);
    },
  },

  methods: {
    refreshOrdersPerCustomerTotals() {
      //se pode selecionar contas ve o total de todos os pedidos no header
      if (
        ability.can("selectTransporterAccount", "via-dashboard-support-point")
      ) {
        this.api
          .get(
            `/api/v1/reports/orders_per_customers_total_only?company_kind=${this.companyKind}`,
            this.getToken()
          )
          .then((response) => {
            var item = response.data[response.data.length - 1];
            this.today_done_quantity = item.orders_today_done.quantity;
            this.today_final_occurrence_quantity =
              item.orders_today_occurrence.quantity;
            this.productivity_quantity = item.orders_productivity.quantity;

            this.today_done_quantity_scheduled =
              item.orders_today_done.quantity_scheduled;
            this.today_final_occurrence_quantity_scheduled =
              item.orders_today_occurrence.quantity_scheduled;
            this.productivity_quantity_scheduled =
              item.orders_productivity.quantity_scheduled;
          })
          .catch((error) => {
            this.$notify({
              title: "Erro de consulta.",
              text: error,
              type: "error",
            });
          });
        // senão ve apenas dos pedidos da pŕopria conta
      } else {
        if (this.$store.state.user?.account_id) {
          this.api
            .get(
              `${process.env.VUE_APP_PYTHON_API}/api/v1/reports/support_points_dashboard?totalOnly=true`,
              {
                params: {
                  transporter_account_id: this.$store.state.user.account_id,
                },
                headers: this.getBearer(),
              }
            )
            .then((response) => {
              var item =
                response.data.table_summary[
                  response.data.table_summary.length - 1
                ];
              if (item) {
                this.today_done_quantity = item.orders_today_done?.quantity;
                this.today_final_occurrence_quantity =
                  item.orders_today_occurrence?.quantity;
                this.productivity_quantity = item.orders_productivity?.quantity;

                this.today_done_quantity_scheduled =
                  item.orders_today_done?.quantity_scheduled;
                this.today_final_occurrence_quantity_scheduled =
                  item.orders_today_occurrence?.quantity_scheduled;
                this.productivity_quantity_scheduled =
                  item.orders_productivity?.quantity_scheduled;
              }
            })
            .catch((error) => {
              this.$notify({
                title: "Erro de consulta.",
                text: error,
                type: "error",
              });
            });
        }
      }
    },

    async searchOrder() {
      let filter = {};
      filter.search_kind = "Busca Rapida";
      filter.search = this.orderFilterSearch;
      let result = await this.$refs.ViaOrdersApi.getOrdersApi(filter, false);
      if (result.orders_count == 0) {
        this.$notify({
          title: "Erro de consulta.",
          text: "Pedido não encontrado.",
          type: "error",
        });
      } else if (result.orders_count == 1) {
        this.$router
          .push({ name: "orders/:id", params: { id: result.orders[0].id } })
          .catch();
      } else {
        this.$notify({
          title: "Erro de consulta.",
          text: "Muitos pedidos encontrados.",
          type: "warning",
        });
      }
    },

    cancelAutoUpdate() {
      clearInterval(this.timer);
    },

    toggleSidebar: () => {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
    },
    toggleMobileSidebar: () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },
    togglesettingsPannel: () => {
      document.querySelector("#right-sidebar").classList.toggle("open");
    },
    clearSession() {
      this.userService.loggout();
      this.$router.replace({ name: "login" }).catch((err) => err);
    },
    editCurrentUser() {
      var userId = localStorage.getItem("user_id");
      this.$router
        .push({ name: "users/:id/edit", params: { id: userId } })
        .catch();
    },
  },

  destroyed() {
    //this.cancelAutoUpdate;
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.brand-logo {
  scale: 1.4;
}

#counters {
  font-size: 0.7rem;
}
</style>
